import { Modal, Input } from "antd";
import { useState } from "react";
import { createReferralCode, checkReferralCode } from "../../api/referrals";
import { getAddress } from "../../utils/address";
import { debounce } from 'lodash';  

export default function CreateCodeModal({open, handleCancel, handleOk}) {
    const [okText, setOkText] = useState("Create Code");    
    const [code, setCode] = useState("");
    const [isCodeValid, setIsCodeValid] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const validateCode = (value) => {
        // 基本验证规则
        if (!value) {
            setIsCodeValid(false);
            return false;
        }
        setErrorMsg("");
        return true;
    };

    const checkCode = debounce(async (value) => {
        if (!validateCode(value)) {
            return;
        }
        const res = await checkReferralCode({code: value});
        setIsCodeValid(res.data.code === 200 && !res.data.data);
        if (res.data.code === 200 && res.data.data) {
            setErrorMsg("code is already used");
        }
        if (res.data.code !== 200) {
            setErrorMsg("code is not valid");
        }
    }, 500);

    const handleCreateCode = async () => {
        setOkText("Creating...");
        const res = await createReferralCode({code: code, address: getAddress()});
        if (res.data.code === 200) {
            handleOk();
        }
    }

    const handleInputChange = async (e) => {
        const value = e.target.value;
        setCode(value);
        checkCode(value);
    }

    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            onOk={handleCreateCode}
            centered
            okText={okText}
            okButtonProps={{ disabled: !isCodeValid }}
        >
            <div className="text-light-emphasis fs-5">Create Referral Code</div>
            <div className="text-light-emphasis">
                You won't be able to edit the referral after it's created. Make sure to double check the details.
            </div>
            <div className="mt-4 d-flex flex-column gap-2">
                <div className="text-light-emphasis">Referral Code</div>
                <Input 
                    size="large" 
                    placeholder="Input referral code" 
                    value={code} 
                    onChange={handleInputChange}
                    status={errorMsg ? "error" : ""} 
                />
                {errorMsg && <div className="text-danger">{errorMsg}</div>}
            </div>
        </Modal>
    )
}
