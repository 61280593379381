import React, { useState, useEffect } from "react";

import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Drawer, Tooltip, Button } from "antd";

import { getAddress } from "../../utils/address";
import { shortenHex } from "../../utils/util";
import CreateCodeModal from "../Modal/createCodeModal";
import { getChainNameById, formatNumberFixed } from "../../utils/util";
import contractAddress from "../../contracts/contract-address.json";
import TokenArtifact from "../../contracts/TDToken.json";
import { getReferralNum, getReferralCode } from "../../api/referrals";
import { getToken } from "../../utils/token";
import { ethers } from "ethers";

export function ProfileDrawer({ open, handleClose, disConnectAccount }) {
  const [tooltip, setTooltip] = useState(false);
  const [createCodeOpen, setCreateCodeOpen] = useState(false);
  const [referralNum, setReferralNum] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [tdBalance, setTdBalance] = useState(0);
  const onClose = () => {
    handleClose(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText("https://tizi.money/app?ref=" + referralCode);
    if (!tooltip) {
      setTooltip(true);
      setTimeout(() => {
        setTooltip(false);
      }, 2000);
    }
  };

  const handleLogout = () => {
    disConnectAccount();
    handleClose(false);
  }

  useEffect(() => {
    const getTdBalance = async () => {
      try {
        if (window.ethereum && getAddress()) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const chainId = await window.ethereum.request({
            method: "eth_chainId",
            params: [],
          });
          const chainName = getChainNameById(chainId);
          const tdToken = new ethers.Contract(contractAddress.TDToken[chainName], TokenArtifact, provider);
          const balance = await tdToken.balanceOf(getAddress());
          setTdBalance(ethers.utils.formatUnits(balance, 18));
        }
      } catch (error) {
        console.log(error);
      }
    }


    if (getAddress() && getToken(getAddress())) {
      getReferralNum({address: getAddress()}).then((res) => {
        setReferralNum(res.data.data);
      });
      getReferralCode({address: getAddress()}).then((res) => {
        setReferralCode(res.data.data);
      });
      getTdBalance();
    }
  }, [getToken(getAddress()), getAddress(), createCodeOpen]);

  return (
    <Drawer title="Profile" onClose={onClose} open={open}>
      <div className="rounded-3 bg-secondary p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-2">
            <UserOutlined className="fs-5 text-white" />
            <div className="text-white fs-5">{shortenHex(getAddress())}</div>
          </div>
          <LogoutOutlined
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
            className="fs-5 text-white cursor-pointer"
          />
        </div>
        <div className="d-flex mt-2">
          <div className="d-flex flex-column">
            <div className="text-light-emphasis fs-6">Balance</div>
            <div className="text-white fs-5">{formatNumberFixed(tdBalance)} TD</div>
          </div>
        </div>
      </div>

      <div className="rounded-3 bg-secondary mt-4 p-3">
        <div className="text-white fs-4">Referrals</div>
        {referralCode ? (
          <div className="d-flex mt-2">
            <div className="d-flex flex-column flex-grow-1">
              <div className="text-light-emphasis fs-6">Code</div>
              <div className="d-flex align-items-center gap-2">
                <div className="text-white fs-5">{ referralCode }</div>
                <Tooltip title="Copied!" open={tooltip}>
                  <CopyOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCopy();
                    }}
                    className="fs-5 text-green cursor-pointer bg-success rounded-2 p-1"
                  />
                </Tooltip>
              </div>
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <div className="text-light-emphasis fs-6">Persons</div>
              <div className="d-flex align-items-center gap-2">
                <div className="text-white fs-5">{ referralNum }</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column mt-2">
            <div className="text-light-emphasis fs-6">Code</div>
            <Button
              onClick={() => {
                setCreateCodeOpen(true);
              }}
            >
              Create Referral Code
            </Button>
          </div>
        )}
      </div>
      <CreateCodeModal
        open={createCodeOpen}
        handleCancel={() => {
          setCreateCodeOpen(false);
        }}
        handleOk={() => {
          setCreateCodeOpen(false);
        }}
      />
    </Drawer>
  );
}
