import { supportedChain } from "./config"
import numeral from "numeral"

const chainName ={
    // "0x61": "bsc-testnet",
    // "0x13881": "polygon_testnet",
    // "0xa": "bsc-testnet",
    "0xaa37dc": "sepolia",
    '0xa': 'optimism',
    "0x89": "polygon",
    // "0xa86a": "avalanche",
    "0x2105": "base",
    "0xa4b1": "arbitrum"
}

export function isSupportChain(chainId){
    return supportedChain.includes(chainId)
}

export function getChainNameById(chainId){
    return chainName[chainId]
}

export function getChainIdByName(chainName){

}

export function formatNumberFixed(num){
    // 检查是否有小数点
    if (num.toString().includes('.')) {
        // 获取小数部分
        const decimalPart = num.toString().split('.')[1];
        // 如果小数点后有六位数，则保留这六位数
        if (decimalPart.length >= 6) {
            return Number(num).toFixed(6);
        }
    }
    // 否则返回原始数字
    return num.toString();
}

export function formatNumberbyNumeral(num) {
    return numeral(num).format('0.[000]a')!= 'NaN'? numeral(num).format('0.[000]a') : num;
}

export function shortenHex(hex, startLength = 4, endLength= 4){
    if (!hex) {
      return ""
    }
    // 确保输入是一个有效的十六进制字符串
    if (hex.startsWith('0x') && hex.length > startLength + endLength + 2) {
      // 获取开始和结尾的字符
      return `0x${hex.substring(2, 2 + startLength)}...${hex.substring(hex.length - endLength)}`;
    }
    // 如果输入不符合预期，返回原字符串
    return hex;
  }