import { Modal, Input, Button } from "antd";
import { useState } from "react";
import { getTokenLogin } from "../../api/referrals";
import { getAddress } from "../../utils/address";
import { setToken } from "../../utils/token";


export default function SignFirstInputCodeModal({open, handleCancel, handleOk, signature}) { 
    const [code, setCode] = useState("");

    const handleSign = async () => {
        const res = await getTokenLogin({code: code, address: getAddress(), signature: signature});
        console.log(res);
        if (res.data.code === 200) {
            setToken(res.data.data.access_token, getAddress());
            handleOk();
        }
    }

    const handleSkip = async () => {
        const res = await getTokenLogin({ address: getAddress(), signature: signature});
        console.log(res);
        if (res.data.code === 200) {
            setToken(res.data.data.access_token, getAddress());
            handleOk();
        }
    }

    return (
        <Modal open={open} onCancel={handleSkip} onOk={handleSign} maskClosable={false} centered okText="Sign" cancelText="Skip">
            <div className="text-light-emphasis fs-5">Sign Referral Code</div>
            <div className="text-light-emphasis">
                Please sign the referral code to complete the process.
            </div>
            <div className="mt-4 d-flex flex-column gap-2">
                <div className="text-light-emphasis">Referral Code</div>
                <Input size="large" placeholder="Referral Code" value={code} onChange={(e) => {setCode(e.target.value)}} />
            </div>
        </Modal>
    )
}

