import { Modal } from "antd";
import { useState } from "react";
import { ethers } from "ethers";
import { getNonce } from "../../api/referrals";
import { getAddress } from "../../utils/address";

export default function SignMessageModal({open, handleCancel, handleOk, handleOkComplete}) {

    const [okText, setOkText] = useState("Sign Message");

    const handleSignMessage = async() => {
        try {
            setOkText("Signing...");

            const res = await getNonce({address: getAddress()});
            const nonce = res.data.data.nonce;

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const singer = provider.getSigner()
            const account = await singer.getAddress();

            // 这是将要发送给用户签名的消息
            // const message = `Nonce:${nonce}`
            const message = `Tizi Money Agreement:By signing this agreement, you acknowledge and agree to the following terms and conditions governing your membership in Tizi found at: https://tizimoney.gitbook.io/tizi/other/terms-and-conditions, Nonce: ${nonce}`;

            const signature = await singer.signMessage(message);
            // All properties on a domain are optional
// const domain = {
//     name: 'Ether Mail',
//     version: '1',
//     chainId: 8453,
//     verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC'
// };

// // The named list of all type definitions
// const types = {
//     Person: [
//         { name: 'name', type: 'string' },
//         { name: 'wallet', type: 'address' }
//     ],
//     Mail: [
//         { name: 'from', type: 'Person' },
//         { name: 'to', type: 'Person' },
//         { name: 'contents', type: 'string' }
//     ]
// };

// // The data to sign
// const value = {
//     from: {
//         name: 'Cow',
//         wallet: '0xCD2a3d9F938E13CD947Ec05AbC7FE734Df8DD826'
//     },
//     to: {
//         name: 'Bob',
//         wallet: '0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB'
//     },
//     contents: 'Hello, Bob!'
// };

// const signature = await singer._signTypedData(domain, types, value);

            console.log('Signature:', signature);



            // 使用 ethers.js 来从签名中恢复出签名者的地址
            // const recoveredAddress = ethers.utils.verifyMessage(message, signature);

            // console.log("Recovered address:", recoveredAddress);

            // 如果恢复出的地址与用户提供的地址一致，则签名有效
            

            // handleOk();
            handleOkComplete(signature, message);
            setOkText("Sign Message");
        } catch (error) {
            setOkText("Sign Message");
        }
    }

    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            onOk={handleSignMessage}
            centered
            okText={okText}
            width={400}
        >
            <div className="d-flex flex-column gap-3">
                <div className="text-light-emphasis fs-6">Verify your account</div>
                <div className="text-light-emphasis">
                    To finish connecting, you must sign a message in your wallet to verify that you are the owner of this account.
                </div>
            </div>
        </Modal>
    )
}
