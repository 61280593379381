 // 封装本地存储的操作
const TOKEN_KEY = 'new-tizi-token'

// 获取 token
export function getToken (address) {
  const tokenList = JSON.parse(localStorage.getItem(TOKEN_KEY)) || {};
  return tokenList[address] || '';
}

// 本地存储 token
export function setToken (token, address) {
  const tokenList = JSON.parse(localStorage.getItem(TOKEN_KEY)) || {};
  tokenList[address] = token;
  localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenList));
}

// 删除 token
export function removeToken (address) {
  const tokenList = JSON.parse(localStorage.getItem(TOKEN_KEY)) || {};
  Reflect.deleteProperty(tokenList, address);
  localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenList));
}

// 判断有无 token
export function hasToken () {
  return !!getToken()
}

