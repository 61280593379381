import axios from 'axios';
import { getAddress, removeAddress } from '../utils/address';
import { getToken, removeToken  } from '../utils/token';

// 创建 axios 实例
const request = axios.create({
  // baseURL: 'http://192.168.2.21:8889/v1/user', // 设置统一的基础 URL
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000, // 请求超时时间
});

// 添加请求拦截器
request.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    // 比如添加 token
    const token = getToken(getAddress());
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.method === 'post') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);


// 添加响应拦截器
request.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    console.log(response)
    if (response.data && response.data.code === 207) {
      removeToken(getAddress())
    }
    return response;
  },
  error => {
     // 全局错误处理
     const errorMessage = error?.message || '未知错误';
     const errorCode = error?.code;
     
     // 处理特定的错误码
     switch(errorCode) {
       case 'ERR_NETWORK':
         console.error('网络错误，请检查网络连接');
         break;
       case 'ECONNABORTED':
         console.error('请求超时');
         break;
       case 'ERR_BAD_REQUEST':
         console.error('错误的请求');
         break;
       default:
         console.error(`请求错误: ${errorMessage}`);
     }
    return new Promise(() => {});
  }
);

export default request;
