import React, { useState } from 'react';

function TimeRangeSelector() {
  const [active, setActive] = useState('1W'); // Default to '1W'
  
  const timeRanges = ['1W', '1M', '3M', '6M', '1Y', 'ALL TIME'];

  return (
    <div className='w-100' style={{ maxWidth: '650px'}}>
      {timeRanges.map((range) => (
        <button
          key={range}
          style={{
            backgroundColor: 'white',
            color: active === range ? 'black': '#687386',
            border: 'none',
            cursor: 'pointer'
          }}
          onClick={() => setActive(range)}
        >
          {range}
        </button>
      ))}
    </div>
  );
}

export default TimeRangeSelector;
