import { Menu, Dropdown, Space, Avatar } from "antd";
import React, { useState, useEffect } from "react";
import {
  DownOutlined,
  UserOutlined
} from "@ant-design/icons";
import { getAddress } from "../utils/address";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../utils/language";
import { useNavigate, useLocation } from "react-router-dom";
import { ProfileDrawer } from "./Drawer/profileDrawer";
import SignMessageModal from "./Modal/signMessageModal";
import ReferredPersonModal from "./Modal/referredPersonModal";
import SignFirstInputCodeModal from "./Modal/signFirstInputCodeModal";
import { useSearchParams } from "react-router-dom";
import { ethers } from "ethers";
import { getTokenLogin, checkUser, checkToken } from "../api/referrals";
import { setToken,getToken } from "../utils/token";


export function TopMenu({ isConnect, disConnectAccount, connectWallet }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  const location = useLocation()
  const [language, setLanguage] = useState(
    getLanguage() === "zh-CN" ? "中文" : "ENGLISH"
  );
  const [currentMenu, setCurrentMenu] = useState(location.pathname)
  const [open, setOpen] = useState(false);
  const [signMessageOpen, setSignMessageOpen] = useState(false);
  const [referredPersonOpen, setReferredPersonOpen] = useState(false);
  const [openSignFirstInputCodeModal, setOpenSignFirstInputCodeModal] = useState(false);
  const [currentSignature, setCurrentSignature] = useState("");
  const items = [
    {
      key: "1",
      label: <div onClick={disConnectAccount}>{t("Disconnect")}</div>,
    },
  ];
  const localItems = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            i18n.changeLanguage("zh-CN");
            setLanguage("中文");
          }}
        >
          中文
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            i18n.changeLanguage("en-US");
            setLanguage("ENGLISH");
          }}
        >
          ENGLISH
        </div>
      ),
    },
  ];
  const menuItem = [
    {
      label: t("Mint"),
      key: '/app',
    },
    // {
    //   label: t("Statistics"),
    //   key: '/data',
    // },
    // {
    //   label: t("Wrap"),
    //   key: '/wrap',
    // },
    {
      label: t("Bridge"),
      key: '/bridge',
    },
  ]
  let connectStatus = undefined;

  const showDrawer = () => {
    setOpen(true);
  };

  if (isConnect) {
    connectStatus = (
      <div onClick={showDrawer}>
        {/* <Dropdown
          menu={{
            items,
          }}
        >
          <div className="address-info" onClick={(e) => e.preventDefault()}>
            {getAddress()}
          </div>
        </Dropdown> */}
         <div className="address-info" onClick={(e) => e.preventDefault()}>
            {getAddress()}
          </div>
      </div>
    );
  } else {
    connectStatus = (
      <button className="btn btn-warning" type="button" onClick={connectWallet}>
        {t("connect")}
      </button>
    );
  }
  const gotoHome = () => {
    navigate('/')
  }
  const clickMenu = (e) => {
    setCurrentMenu(e.key)
    if (e.key === '/app') {
      navigate('/app')
    }else if (e.key === '/data') {
      navigate('/data')
    }else if (e.key === '/bridge') {
      navigate('/bridge')
    }
  }

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const refCode = searchParams.get("ref");
    if (refCode) {
      setReferredPersonOpen(true);
    }
    if (getAddress() && !getToken(getAddress())) {
      setSignMessageOpen(true);
    }
  }, [searchParams, getToken(getAddress())]);

  const loginWithCode = async() => {
    const refCode = searchParams.get("ref");
    const res = await getTokenLogin({code: refCode, address: getAddress(), signature: currentSignature});
      if (res.data.code === 200) {
        setSearchParams({})
        setToken(res.data.data.access_token, getAddress());
        setReferredPersonOpen(false);
      }
  }

  const handleLoginSignMessage = () => {
    setSignMessageOpen(true);
  }

  const handleSignModalCancel = () => {
    disConnectAccount();
    setSignMessageOpen(false);
  }

  const handleSignModalOk = async(signature, message) => {
    const res = await checkUser({address: getAddress()});
    console.log(res); 
    const refCode = searchParams.get("ref");
    
    // const resp = await checkToken({address: getAddress(), signature: signature, message: message});
    //   if (resp.data.code === 200) {
    //     setToken('ssss', getAddress());
    //     setSignMessageOpen(false);
    //   }
    if (refCode) {
      setCurrentSignature(signature);
      setSignMessageOpen(false);
    }
    if (res.data.code === 200 && !res.data.data && !refCode) {
      setCurrentSignature(signature);
      setSignMessageOpen(false);
      setOpenSignFirstInputCodeModal(true);
    }
    if (res.data.code === 200 && res.data.data && !refCode) {
      const res = await getTokenLogin({address: getAddress(), signature: signature});
      if (res.data.code === 200) {
        setToken(res.data.data.access_token, getAddress());
        setSignMessageOpen(false);
      }
    }
  }

  return (
    <div className="top-menu-app">
      <div className="top-container-app container">
        <div style={{flex: '1', display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: 10}} className="top-menu-left top-menu-click" onClick={() => gotoHome()}>
            <Avatar
              src={
                <img
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  style={{ objectFit: "contain" }}
                  alt="avatar"
                />
              }
            />
            Tizi
          </div>
          <Menu onClick={clickMenu} selectedKeys={[currentMenu]} mode="horizontal" items={menuItem} style={{minWidth: 0, flex: 'auto'}} />
        </div>
        <div className="top-right">
          <div style={{ marginRight: "10px" }}>
            <Dropdown
              menu={{
                items: localItems,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {language}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          {connectStatus}
        </div>
        <div className="top-right-phone" onClick={showDrawer}>
              {isConnect? <UserOutlined/> : <></>}
        </div>
        <ProfileDrawer open={open} handleClose={onClose} disConnectAccount={disConnectAccount}  />
        <ReferredPersonModal loginWithCode={loginWithCode} handleSignMessage={handleLoginSignMessage} open={referredPersonOpen} handleCancel={() => {setReferredPersonOpen(false)}} handleOk={() => {setReferredPersonOpen(false)}} connectWallet={connectWallet} />
        <SignFirstInputCodeModal open={openSignFirstInputCodeModal} signature={currentSignature} handleCancel={() => {setOpenSignFirstInputCodeModal(false)}} handleOk={() => {setOpenSignFirstInputCodeModal(false)}} />
        <SignMessageModal open={signMessageOpen} handleCancel={handleSignModalCancel} handleOk={handleSignModalOk} handleOkComplete={handleSignModalOk} />
      </div>
    </div>
  );
}
