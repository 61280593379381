import { Modal, Button } from "antd";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAddress } from "../../utils/address";
import { checkUser, checkReferralCode } from "../../api/referrals";
import { getToken } from "../../utils/token";



export default function ReferredPersonModal({open, handleCancel, handleOk, connectWallet, loginWithCode, handleSignMessage}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const refCode = searchParams.get("ref");
    // isFirstUser : true 表示不是第一次使用，false 表示是第一次使用
    const [isFirstUser, setIsFirstUser] = useState(false);
    const [isExistCode, setIsExistCode] = useState(false)

    const handlePersonCancel = () => {
        setSearchParams({});
        handleCancel();
    }

    const handleConnectWallet = async () => {
        await connectWallet();
        loginWithCode();
    }

    const UserModalBtn = () => {
        if (!getAddress()) {
            return (
                <div className="mt-4 w-100">
                    <Button className="w-100" size="large" onClick={handleConnectWallet}>Connect Wallet</Button>
                </div>
            )
        }else{
            if (!isFirstUser && isExistCode) {
                return (
                    <div className="mt-4 w-100">
                        <Button className="w-100" size="large" onClick={loginWithCode}>Use Referral Code</Button>
                    </div>
                )
            }
        }
        return ''
    }

    const ReferredText = () => {
        if (getAddress()) {
            if (!isExistCode) {
                return "Your referral was not found"
            }else{
                if (isFirstUser) {
                    return "You already have a account in Tizi Money. Thanks for being a Tizi Member"
                }else{
                    return "You are connected to Tizi Money, use your referral code and start"
                }
            }
        } else{
             return "Connect your wallet and agree to the terms and conditions to join."
        }
    }

    useEffect(() => {
        const checkFisrtUser = async () => {
            const res = await checkUser({address: getAddress()});
            console.log(res);
            if (res.data.code === 200) {
                setIsFirstUser(res.data.data);
            }
        }
        const checkReferralCodeExist = async () => {
            const res = await checkReferralCode({code: refCode});
            console.log(res);
            if (res.data.code === 200) {
                setIsExistCode(res.data.data);
            }
        }

        checkFisrtUser();
        checkReferralCodeExist()
        if (getAddress() && !getToken(getAddress())) {
            handleSignMessage();
        }

    }, []);

    return (
        <Modal
            open={open}
            onCancel={handlePersonCancel}
            onOk={handleOk}
            centered
            okText="Create"
            footer={null}
        >
            <div className="d-flex flex-column gap-2 align-items-center">
                <div className="text-light-emphasis fs-4">Welcome to Tizi Money</div>
                <div className="text-light-emphasis fs-2 fw-bold">You've been referred</div>
                    <div className="text-light-emphasis fs-5">with ref code: {refCode}</div>
                <div className="text-light-emphasis fs-5" style={{maxWidth: "400px"}}>
                    <ReferredText />
                </div>

                <UserModalBtn />
            </div>

        </Modal>
    )
}
